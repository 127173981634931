//defining main constant
@use '../base';



.layout{
    display: flex;
    color:base.$primary-text-color;
   

    .main-content{
        display: flex;
        flex:4;
        background-color: base.$main-background-color;
        flex-direction: column;
        gap:10px;
    }
}