@use '../base';

$navbar-color:#5da3e0;
.navbar{
    font-size: 20px;
    background-color: $navbar-color;
    padding: 8px;
    border-bottom: 1px solid rgb(218, 221, 223);
    background-color: base.$main-background-color;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    .user_interaction{
        display: flex;
        gap:20px;
        margin-right: 20px;
    }
    .icon{
        font-size: 28px;
    }
}

