.metric_info{
    
    display: flex;
    justify-content: space-between;
    min-width: 240px;

    .info_col{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100px;

        .title{
            font-size: 1.2em;
        }

        .value{
            font-size: 1.8em;
            font-weight: bold;
        }

        .metric_value_change{
            display: flex;
            gap:8px;

            .value_percent{
                display: block;
            }
            .icon{
                
            }
           
        }
        .up{
            color:green;
        }
        .down{
            color:red;
        }

    }

}